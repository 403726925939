import { BaseForm, Validations, GroupValidator } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"
import { HttpClient } from "@angular/common/http"
export class UsuarioRegistroUMayorForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    public static get formParams(): any {
        let companyName: string = ""
        return {
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Primer apellido",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Segundo apellido",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmail }]
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                validations: [Validators.required, Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            rut: {
                label: "RUT",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateRut],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueRut }]
            },
            telefono: {
                label: "Teléfono",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateTelefonoChile]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
