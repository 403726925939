<nav class="navbar clearfix" id="inicio">
    <div class="clearfix">
        <div class="navbar-header">
            <div class="navbar-logo-container">
                <div class="navbar-logo-container-inner">
                    <a
                        href="#section1"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOutExpo'"
                    >
                        <img
                            class="contenLogo"
                            src="assets/img/landing/logo_up.png"
                            alt="{{ this.config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>

        <div class="small-login">
            <div class="small-login-align">
                <small-login-usuario
                    class="small-login-component"
                    [enablePasswordRecovery]="true"
                ></small-login-usuario>
            </div>
        </div>
    </div>
    <div class="navbar-collapse collapse topmenu">
        <ul class="nav navbar-nav">
            <li>
                <a
                    href="#inicio"
                    easyScrollTo
                    [easyScrollToPadding]="0"
                    [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'"
                >
                    Inicio
                </a>
            </li>
            <li>
                <a
                    href="#section2"
                    easyScrollTo
                    [easyScrollToPadding]="40"
                    [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'"
                >
                    ¿Qué es EnsayaOnline?
                </a>
            </li>
            <li>
                <a
                    href="#section3"
                    easyScrollTo
                    [easyScrollToPadding]="40"
                    [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'"
                >
                    Herramientas
                </a>
            </li>
            <li>
                <a
                    href="#section4"
                    easyScrollTo
                    [easyScrollToPadding]="40"
                    [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'"
                >
                    Cómo estudiar
                </a>
            </li>
        </ul>
    </div>
</nav>

<div class="contenedor" data-spy="scroll" data-target=".navbar-puntaje">
    <div class="mascara">
        <!-- Registro -->
        <div class="wrapper" id="section1">
            <div class="caja">
                <div class="caja-margin">
                    <br />
                    <div class="text-header"></div>
                    <br />
                </div>
            </div>
        </div>

        <div class="wrapper">
            <!-- Beneficios -->
            <div id="section2">
                <div class="caja">
                    <div class="contentRegistro">
                        <!-- Componente de registro -->
                        <div class="caja-inner-margin">
                            <usuario-registro-umayor
                                (usuarioEmitter)="onRegistroDone($event)"
                            ></usuario-registro-umayor>
                        </div>
                    </div>
                    <div class="caja-inner-margin borderTop-x">
                        <div class="row">
                            <div class="col-md-5 col-sm-12">
                                <span class="titulos">¿QUÉ ES ENSAYA ONLINE?</span>
                            </div>
                            <div class="col-md-7 col-sm-12 bordeOrange-y">
                                <p>
                                    <b>EnsayaOnline</b>
                                    es una plataforma de Universidad Mayor con herramientas integradas funcionales que
                                    te ayudan a preparar la Prueba de Acceso a la Educación Superior para tu Admisión
                                    2025. Incluye clases Prueba de Acceso a la Educación Superior online vía streaming
                                    en tiempo real con contenidos alineados al temario DEMRE, material descargable, mini
                                    ensayos con corrección y resultado inmediato, y estadísticas; Al igual que un
                                    preuniversitario pone todos estos elementos a tu disposición de una manera flexible
                                    para maximizar tus estudios y mejorar tu puntaje.
                                </p>
                                <p>
                                    Te proponemos una metodología de estudio que puedes seguir al ritmo que estimes
                                    conveniente.
                                </p>
                                <p>
                                    Te recomendamos además estar constantemente informado acerca del calendario DEMRE,
                                    repasar con periodicidad, identificar tus fortalezas y debilidades y realizar los
                                    ensayos Prueba de Acceso a la Educación Superior disponibles tantas veces puedas.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Herramientas -->
            <div class="caja" id="section3">
                <div class="caja-inner-margin">
                    <span class="titulos">HERRAMIENTAS</span>
                    <div class="contentHerramientas">
                        <div class="itemA">
                            <div class="herramientas-cell">
                                <h2>Clases tipo preuniversitario</h2>
                                <span class="centered">
                                    <img
                                        src="https://s3-us-west-1.amazonaws.com/psuonline-umayor/img-en-vivo.png"
                                        alt="streaming"
                                    />
                                </span>
                                <h3>Vía streaming</h3>
                                <p>
                                    Accede a ver clases en vivo vía streaming y haz todas las preguntas que tengas de la
                                    clase, en tiempo real.
                                </p>
                            </div>
                        </div>
                        <div class="itemB">
                            <div class="herramientas-cell">
                                <h2>Biblioteca de material clases Prueba de Acceso a la Educación Superior</h2>
                                <span class="centered">
                                    <img
                                        src="https://s3-us-west-1.amazonaws.com/psuonline-umayor/img-presentaciones.png"
                                        alt="streaming"
                                    />
                                </span>
                                <h3>Presentaciones</h3>
                                <p>
                                    Accede a todas las presentaciones de las clases,
                                    <em>¡visualiza y descarga!</em>
                                </p>
                            </div>
                        </div>
                        <div class="itemC">
                            <div class="herramientas-cell">
                                <h2>Ensayos Online</h2>
                                <span class="centered">
                                    <img
                                        src="https://s3-us-west-1.amazonaws.com/psuonline-umayor/img-preguntas.png"
                                        alt="ensayos"
                                    />
                                </span>
                                <h3>20 preguntas</h3>
                                <p>Pon a prueba tus contenidos realizando un ensayo breve diferente cada mes.</p>
                            </div>
                        </div>
                        <div class="itemD">
                            <div class="herramientas-cell">
                                <h2>Revisión instantánea de tu ensayo online</h2>
                                <span class="centered">
                                    <img
                                        src="https://s3-us-west-1.amazonaws.com/psuonline-umayor/img-resultados.png"
                                        alt="resultados"
                                    />
                                </span>
                                <h3>Revisa tus ponderaciones</h3>
                                <p>
                                    Evalúa tú progreso por asignatura y detecta cuáles pruebas necesitan más dedicación.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Como trabajar -->
            <div class="caja" id="section4">
                <div class="caja-inner-margin borderBotton-x">
                    <span class="titulos">Metodología de estudio</span>
                    <div class="contenMetdologia">
                        <div class="itemsMetodologia1">
                            <div class="cajaNumeros">
                                <h2>1.</h2>
                            </div>
                            <div class="descripcionNumeros"><h2>ASISTE A CLASES EN VIVO STREAMING</h2></div>
                        </div>
                        <div class="itemsMetodologia4">
                            <div class="cajaNumeros">
                                <h2>4.</h2>
                            </div>
                            <div class="descripcionNumeros"><h2>PONTE A PRUEBA</h2></div>
                        </div>
                        <div class="itemsMetodologia2">
                            <div class="cajaNumeros">
                                <h2>2.</h2>
                            </div>
                            <div class="descripcionNumeros"><h2>DESCARGA MATERIAL</h2></div>
                        </div>
                        <div class="itemsMetodologia5">
                            <div class="cajaNumeros">
                                <h2>5.</h2>
                            </div>
                            <div class="descripcionNumeros"><h2>REVISA Y CORRIGE</h2></div>
                        </div>
                        <div class="itemsMetodologia3">
                            <div class="cajaNumeros">
                                <h2>3.</h2>
                            </div>
                            <div class="descripcionNumeros"><h2>REPASA</h2></div>
                        </div>
                        <div class="itemsMetodologia6">
                            <div class="cajaNumeros">
                                <h2>6.</h2>
                            </div>
                            <div class="descripcionNumeros"><h2>MIDE TU PROGRESO</h2></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer>
            <div class="img-container">
                <img src="assets/img/landing/logo_nav.png" alt="umayor.cl - 600 328 1000" />
            </div>
            <div class="text-center">Si no puedes acceder, escríbenos a contactoumayor@ogr.cl</div>
            <div class="footer-info"></div>
        </footer>
    </div>
</div>
