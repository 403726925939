import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
//
@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    config: typeof config

    constructor(private router: Router, public authService: AuthService) {}

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
    }

    onRegistroDone(usuario: any) {
        this.authService.updateReferer(usuario.id)
    }
}
