<div class="registro">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-sm-6">
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuarioRegistro['nombre']"
                    [key]="'nombre'"
                ></form-input>

                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuarioRegistro['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>

                <form-input
                    [form]="form"
                    [params]="params['apellido_materno']"
                    [(value)]="usuarioRegistro['apellido_materno']"
                    [key]="'apellido_materno'"
                ></form-input>

                <form-input
                    [form]="form"
                    [params]="params['rut']"
                    [(value)]="usuarioRegistro['rut']"
                    [key]="'rut'"
                ></form-input>

                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuarioRegistro['email']"
                    [key]="'email'"
                ></form-input>

                <form-input
                    [form]="form"
                    [params]="params['telefono']"
                    [(value)]="usuarioRegistro['telefono']"
                    [key]="'telefono'"
                ></form-input>
            </div>
            <div class="col-sm-6">
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuarioRegistro['password']"
                    [key]="'password'"
                ></form-input>

                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuarioRegistro['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>

                <div>
                    <embed-preferencia-carrera-sedes-by-sede
                        #preferencias
                        [universidadesNombres]="universidades"
                        [form]="form"
                        [amount]="3"
                        (emitPreferencias)="updatePreferencias($event)"
                    ></embed-preferencia-carrera-sedes-by-sede>
                </div>

                <div *ngIf="sent" class="alert alert-success alert-dismissable">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
                    Se han enviado intrucciones de confirmación a tu correo electrónico
                </div>
                <div *ngIf="sending" class="alert alert-info alert-dismissable">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
                    Enviando formulario ...
                </div>
                <br />
                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">
                    {{ save_button_text | easyplaceholder: "Registrarme" }}
                </button>
            </div>
        </div>
    </form>
</div>
