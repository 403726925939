import { Component, OnInit, Output, EventEmitter, Injector, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { UsuarioRegistroUMayor, UsuarioRegistroUMayorForm, Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { EmbedPreferenciaCarreraSedesBySedeComponent } from "../preferencia_carrera_sedes/embed_preferencia_carrera_sedes_by_sede.component"

import { BaseRegisterComponent } from "./base-register/base-register.component"

declare let gtag: Function

@Component({
    selector: "usuario-registro-umayor",
    templateUrl: "register_umayor.form.component.html",
    styleUrls: ["register_umayor.component.scss"]
})
export class RegisterUMayorComponent extends BaseRegisterComponent implements OnInit {
    usuarioRegistro: UsuarioRegistroUMayor = new UsuarioRegistroUMayor()
    usuarioRegistroForm: UsuarioRegistroUMayorForm = new UsuarioRegistroUMayorForm()
    sent: boolean = false
    sending: boolean = false
    params: any = UsuarioRegistroUMayorForm.formParams
    form: UntypedFormGroup
    save_button_text = "Guardar"

    @Output() usuarioEmitter: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("preferencias") preferencias: EmbedPreferenciaCarreraSedesBySedeComponent
    universidades: string[] = ["Universidad Mayor"]

    constructor(protected router: Router, protected usuariosService: Usuarios, protected injector: Injector) {
        super(usuariosService, router, null, null)
    }

    ngOnInit() {
        this.form = UsuarioRegistroUMayorForm.getForm(this.usuarioRegistro, null, this.injector)
    }

    save() {
        this.sent = false
        this.sending = true
        super.save()
    }

    afterSave(usuario: Usuario) {
        this.sending = false
        this.resetForm()
        this.sent = true

        // Se comenta ya que se reemplazo por etiquetas implementadas en GTM, pero si no funcionan se ocupará este código.
        // this.gtagReportConversionSantiago()
        // this.gtagReportConversionTemuco()
        this.usuarioEmitter.emit(usuario)
    }

    updatePreferencias(preferencias) {
        this.usuarioRegistro.preferenciaCarreraSedes = preferencias
    }

    resetForm() {
        this.usuarioRegistro = new UsuarioRegistroUMayor()
        this.form.markAsPristine()
        this.form.markAsUntouched()
    }

    // Santiago
    gtagReportConversionSantiago(url = undefined) {
        console.log("gtag santiago report")
        var callback = function () {
            if (typeof url != "undefined") {
                window.location.href = url
            }
        }

        gtag("event", "conversion", { send_to: "AW-991885914/fnC8CPbdgaIBENr0-9gD" })
        // this.angulartics2.eventTrack.next({
        //     action: "conversion",
        //     properties: {
        //         gstCustom: {
        //             send_to: "AW-991885914/fnC8CPbdgaIBENr0-9gD"
        //             // event_callback: callback
        //         }
        //     }
        // })

        return false
    }

    // Temuco
    gtagReportConversionTemuco(url = undefined) {
        var callback = function () {
            if (typeof url != "undefined") {
                window.location.href = url
            }
        }

        gtag("event", "conversion", { send_to: "AW-996257765/kuKRCN-3-qEBEOXfhtsD" })
        // this.angulartics2.eventTrack.next({
        //     action: "conversion",
        //     properties: {
        //         gstCustom: {
        //             send_to: "AW-996257765/kuKRCN-3-qEBEOXfhtsD",
        //             event_callback: callback
        //         }
        //     }
        // })

        return false
    }
}
