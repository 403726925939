<ng-template [ngIf]="isLoggedIn()">
    <div class="small-login">
        <div class="clearfix">
            <button (click)="logout()" *ngIf="isLoggedIn()" class="btn btn-default pull-right btn_style">
                Cerrar sesión
            </button>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="!isLoggedIn()">
    <div class="small-login horizontal">
        <form novalidate [formGroup]="form" (ngSubmit)="login()">
            <div class="contenidoForm">
                <div class="itemInput">
                    <div class="doscampos">
                        <div class="form-group">
                            <input
                                type="text"
                                name="email"
                                [(ngModel)]="email"
                                placeholder="Email"
                                *ngIf="!isLoggedIn()"
                                class="form-control"
                                formControlName="email"
                            />
                        </div>
                        <div class="form-group">
                            <input
                                type="password"
                                name="password"
                                [(ngModel)]="password"
                                placeholder="Contraseña"
                                *ngIf="!isLoggedIn()"
                                class="form-control"
                                formControlName="password"
                            />
                        </div>
                        <div class="mensaje-error" *ngIf="formHasErrors()">
                            <div *ngIf="invalidLogin">
                                <p>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</p>
                            </div>
                            <div *ngIf="checkEmail()"><p>Debes incluir un correo electrónico o RUT válido.</p></div>
                            <div *ngIf="checkPassword()"><p>Debes incluir una contraseña.</p></div>
                        </div>
                    </div>
                </div>
                <div class="itemButton">
                    <cui-button type="submit" class="btn-ingresa" *ngIf="!isLoggedIn()">Ingresar</cui-button>
                    <div class="pwd-reset" *ngIf="enablePasswordRecovery">
                        <a [routerLink]="['/usuarios/passwords/forgot']">Restablecer contraseña</a>
                    </div>
                    <!-- <div class="row">
							<div class="col-xs-6">
								<div class="checkbox" *ngIf="enableRememberMe">
									<label>
										<input type="checkbox"/>
										Recordarme
									</label>
								</div>
							</div>
							<div class="col-xs-6">
								<button type="submit" class="btn-ingresa btn" *ngIf="!isLoggedIn()">Ingresar</button>
							</div>
						</div> -->
                </div>
            </div>
        </form>
    </div>
</ng-template>

<request-reset-password></request-reset-password>

<!-- <ng-template [ngIf]="!isLoggedIn()">
	<div class="small-login horizontal">
		<form novalidate [formGroup]="form" (ngSubmit)="login()">
      <div class="pwd-reset" *ngIf="enablePasswordRecovery">
        <a [routerLink]="['/usuarios/passwords/forgot']">Restablecer contraseña</a>
      </div>
			<div class="doscampos">
				<div class="form-group">
					<input type="text" name="email" [(ngModel)]="email" placeholder="Email" *ngIf="!isLoggedIn()" class="form-control" [formControl]="form.controls['email']">
				</div>
				<div class="form-group">
					<input type="password" name="password" [(ngModel)]="password" placeholder="Contraseña" *ngIf="!isLoggedIn()" class="form-control" [formControl]="form.controls['password']">
				</div>
				<div class="mensaje-error" *ngIf="formHasErrors()">
					<div *ngIf="invalidLogin"><p>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</p></div>
					<div *ngIf="checkEmail()"><p>Debes incluir un correo electrónico o RUT válido.</p></div>
					<div *ngIf="checkPassword()"><p>Debes incluir una contraseña.</p></div>
				</div>
			</div>
			<div class="hide-overflow">
				<div class="row">
					<div class="col-xs-6">
						<div class="checkbox" *ngIf="enableRememberMe">
							<label>
								<input type="checkbox"/>
								Recordarme
							</label>
						</div>
					</div>
					<div class="col-xs-6">
						<button type="submit" class="btn-ingresa btn" *ngIf="!isLoggedIn()">Ingresar</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template> -->
