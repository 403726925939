import { BaseModel, Validations, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { UsuarioChile } from "./usuario_chile.model"
import { UsuarioRegistroBase } from "./usuarios_registro_base.model"
import { PreferenciaCarreraSede } from "@puntaje/carreras/api-services"

export class UsuarioRegistroUMayor extends UsuarioRegistroBase {
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public password: string
    public password_confirmation: string
    public rut: string
    public telefono: string

    public preferenciaCarreraSedes: PreferenciaCarreraSede[] = []

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    public nombreCompleto() {
        return this.nombre + " " + this.apellido_paterno + " " + this.apellido_materno
    }

    public toString() {
        return this.email
    }

    public toUsuario() {
        var user = new Usuario()
        user.nombre = this.nombre
        user.apellido_paterno = this.apellido_paterno
        user.apellido_materno = this.apellido_materno
        user.email = this.email
        user.password = this.password
        user.password_confirmation = this.password_confirmation
        user.pais_id = 1 // 1 - chile, 2 - colombia
        user.rol_id = 3 //Generalizar, debería siempre ser 3 (alumno)
        user.usuario_colombia = null
        user.usuario_chile = new UsuarioChile()
        user.usuario_chile.rut = this.rut
        user.usuario_chile.telefono = this.telefono
        user.preferencia_carrera_sedes = this.preferenciaCarreraSedes

        return user
    }
}
